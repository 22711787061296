"use client";

import { Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";

const Popup = ({ title, open, setOpen, children, sx }: {
  children: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  sx?: any
}) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, setOpen]);

  return (
    <Box
      sx={{
        display: open ? "flex" : "none",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "#00000020",
        backdropFilter: "blur(5px)",
        zIndex: 1001,
      }}
      role="dialog"
      tabIndex={open ? 0 : -1}
      onClick={() => setOpen(false)} // Close when clicking outside the modal
    >
      <Box
        ref={popupRef}
        sx={{
          padding: "1.5rem 2rem",
          width: { lg: "60%", sm: "90%", xs: "85%" },
          backgroundColor: "#fff",
          borderRadius: "0.5rem",
          zIndex: 1002, // Ensures popup is above backdrop
          transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
          transform: open ? "scale(1)" : "scale(0.9)",
          opacity: open ? 1 : 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          maxHeight: { lg: '85vh', sm: 'min-content', xs: 'min-content' },
          overflow: 'auto',
          ...sx,
        }}
        onClick={(e) => e.stopPropagation()} // Prevent close on clicking inside the modal
      >


        {children}
      </Box>
    </Box>

  );
};

export default Popup;