"use client";

import React, { memo } from "react";
import parse from "html-react-parser";
import DownloadButton from "./DownloadButton";
import CustomImage from "../../CustomImage/CustomImage";

const BannerSectionStyle8 = ({
  imgUrl,
  imgAlt,
  bgUrl,
  title,
  subTitle,
}) => {
  return (
    <>
      <div
        className="container cs-style-8 cs-radius-25"
        id="gift"
        style={{
          scrollMarginTop: "230px",
          background: `url(${bgUrl})`,
          padding: 0,
        }}>
        <div
          className="cs-banner cs-style-8 cs-radius-25"
          role="img">
          <CustomImage
            src={imgUrl}
            className="cs-custom-banner-img"
            alt={imgAlt || "Banner Image"}
           
            width={490}
            height={781}
            quality="5%"
          />
          <div className="cs-banner-in">
            <h2 className="cs-banner-title cs-fs-72 cs-white-color">
              {parse(title)}
            </h2>
            <p className="cs-banner-subtitle cs-heading-color cs-fs-20 mb-8">
              {parse(subTitle)}
            </p>
            <DownloadButton />
          </div>
        </div>
      </div>
    </>
  );
};
export default memo(BannerSectionStyle8);
