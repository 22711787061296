import React from "react";
import Rating from "../Rating";
import { Box } from "@mui/material";

export default function TestimonialStyle3({
  text,
  ratingNumber,
}) {
  return (
    <Box
      className="cs-testimonial cs-style-3 cs-radius-20 cs-white-bg text-center testimonials-text"
      sx={{
        overflowY: "auto",
        paddingTop: "2rem",
      }}>
      
      <Rating ratingNumber={ratingNumber} />
      <div className="cs-fs-20" style={{color:'#222'}}>{text}</div>
    </Box>
  );
}
