"use client";

import emailjs from "@emailjs/browser";
import { Grid, Box, Button, Checkbox } from "@mui/material";
import React, { useState } from "react";
import { useLocale, useTranslations } from "next-intl";
import { useForm } from "react-hook-form";
import Link from "next/link";
import toast from "react-hot-toast";

interface NewsLetterFormProps {
  emailJsTemp: { en: string; es: string };
  type: string;
  formId: string;
  darkBg?: boolean;
  linkToOpen?: string;
}

const NewsLetterForm = ({
  emailJsTemp,
  darkBg,
  formId,
  type,
  linkToOpen,
}: NewsLetterFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const currentLang = useLocale();
  const t = useTranslations("forms");
  const AL = useTranslations("ariaLabels");

  const onSubmit = handleSubmit((data) => {
    emailjs
      .send(
        "service_pjujdf9",
        emailJsTemp[currentLang],
        { name: data.name, email: data.email },
        "yR82CfaRwg7YEBsWj"
      )
      .then(() => {
        setSubmitted(true);
        reset();
        if (linkToOpen) window.open(linkToOpen, "_blank");
      })
      .catch(() => {
        toast.error("Failed to send Booking request");
      });
  });

  const getFieldStyle = (error: boolean) => ({
    padding: "0.75rem",
    borderRadius: "20px",
    backgroundColor: "white",
    border: error ? "1px solid red" : "1px solid #ccc",
  });

  if (submitted) {
    return (
      <Box sx={{ padding: "0px 20px" }}>
        <h3 style={{ marginBottom: "0.5rem", color: darkBg ? "#fff" : "#033449" }}>
          {t(`newsletterForm.success.${type}.successTitle`)}
        </h3>
        <p style={{ color: darkBg ? "#fff" : "#033449" }}>
          {t(`newsletterForm.success.${type}.successMessage`)}
        </p>
      </Box>
    );
  }

  return (
    <form onSubmit={onSubmit} className="cs-style-1 cs-radius-30" id={formId}>
      <Grid container rowGap={1} columnSpacing={1}>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <Grid container rowGap={1} columnSpacing={1} columns={9}>
            {/* Name Field */}
            <Grid item lg={4} md={4} sm={9} xs={9} sx={{ marginBottom: "1rem" }}>
              <label className="cs-input-label" style={{ color: darkBg ? "#fff" : "#033449" }}>
                {t("fields.name")}
              </label>
              <input
                aria-label="Form name field"
                {...register("name", {
                  required: t("validation.required"),
                  pattern: { value: /^[a-zA-Z\s]+$/, message: t("validation.string") },
                })}
                style={getFieldStyle(!!errors.name)}
                type="text"
                className="cs-form-field custom"
                placeholder={t("placeholders.name")}
              />
              {errors.name && (
                <p style={{ color: darkBg ? "#41bbcb" : "red", marginTop: "0.5rem" }}>
                  {errors.name.message?.toString()}
                </p>
              )}
            </Grid>

            {/* Email Field */}
            <Grid item lg={5} md={5} sm={9} xs={9} sx={{ marginBottom: "1rem" }}>
              <label className="cs-input-label" style={{ color: darkBg ? "#fff" : "#033449" }}>
                {t("fields.email")}
              </label>
              <input
                aria-label="Form email field"
                {...register("email", {
                  required: t("validation.required"),
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: t("validation.notValidEmail"),
                  },
                })}
                style={getFieldStyle(!!errors.email)}
                type="email"
                className="cs-form-field custom"
                placeholder={t("placeholders.email")}
              />
              {errors.email && (
                <p style={{ color: darkBg ? "#41bbcb" : "red", marginTop: "0.5rem" }}>
                  {errors.email.message?.toString()}
                </p>
              )}
            </Grid>

            {/* Checkbox and Policy Links */}
            <Grid item lg={9} md={9} sm={9} xs={9} sx={{ marginBottom: "1rem" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <input
                  aria-label="Polices and Conditions confirmation"
                  {...register("checkbox", {
                    required: t("validation.required"),
                  })}
                  id={`${formId}-acceptance-checkbox`}
                  className="checkbox"
                  type="checkbox"
                />
                <label
                  htmlFor="acceptance-checkbox"
                  style={{ margin: 0, color: darkBg ? "#fff" : "#033449", userSelect: "none" }}
                >
                  {t("newsletterForm.p1")}
                  <Link
                    href={`/${currentLang}/privacy-policy`}
                    target="_blank"
                    aria-label={AL("links.privacyPolicy")}
                    className="link-style"
                  >
                    {t("newsletterForm.l1")}
                  </Link>
                  {t("newsletterForm.p2")}
                  <Link
                    href={`/${currentLang}/terms-conditions`}
                    target="_blank"
                    aria-label={AL("links.terms")}
                    className="link-style"
                  >
                    {t("newsletterForm.l2")}
                  </Link>
                </label>
              </Box>
              {errors.checkbox && (
                <p style={{ color: darkBg ? "#41bbcb" : "red", marginTop: "0.5rem" }}>
                  {errors.checkbox.message?.toString() || "An error occurred"}
                </p>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={3} md={3} sm={12} xs={12} sx={{ marginTop: { lg: "2.5rem", md: "2.5rem", sm: 0, xs: 0 } }}>
          <Button
            type="submit"
            id={`${formId}-btn`}
            sx={{
              backgroundColor: darkBg ? "white" : "#033449",
              borderRadius: "20px",
              width: "100%",
              padding: ".75rem",
              color: darkBg ? "#033449" : "white",
              fontWeight: "bold",
              textTransform: "none",
              ":hover": { backgroundColor: darkBg ? "#f0f0f0" : "#033449" },
            }}
          >
            {t(`newsletterForm.btnText.${type}`)}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewsLetterForm;
